<template>
<div class="bottom-right  py-3 px-4">
  <div class="header d-flex justify-space-between mb-2">
    <span class="left">当前进展</span>    
  </div>
  <div class="text-h6 grey--text" style="padding-top:100px;padding-left:200px;" v-if="progressList.length==0">
    请先更新关键成果的进展情况
  </div>
  <div v-else class="progress-container mb-4 py-6 px-3" v-for="(p, i) in progressList" :key="i">
    <div class="d-flex justify-space-between align-center">
      <span style="color: #83ba5a" class="mr-2">{{p.date}}</span>
      <span class="progress-circle mr-2">
        <span class="progress-circle-inner"></span>
      </span>
      <span v-if="p.pr_parent_type==0" style="color: #83898f;">O:</span>
      <span v-else style="color: #83898f;">k:</span>
      <span  class="ml-5">{{ !!p.parentName?p.parentName:'' }}</span>
      <v-spacer></v-spacer>
      <span>{{p.employeeName}}</span>
    </div>
    <div class="ml-12 mt-1">
      <span style="color: #83ba5a;">进展:</span>
      <span style="color: #36434D;" v-html="p.pr_description">
      </span>
    </div>
  </div>
</div>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex';

export default {
  name: 'Progress',
  components: {},
  props: [],
  data() {
    return {}
  },
  computed: {
    ...mapGetters('okr', ['okrDetail']),
    progressList: function () {
      let temp = [];
      
      if(!!this.okrDetail) {
        if(!!this.okrDetail.ob_recent_pr) {
          let objectProgress = this.okrDetail.ob_recent_pr;
          objectProgress.parentName = this.okrDetail.ob_name;
          temp.push(objectProgress);
        }
        if(!!this.okrDetail.ob_results) {
          this.okrDetail.ob_results.map(kr => {
            if(!!kr.kr_recent_pr) {
              let krProgress = kr.kr_recent_pr;
              krProgress.parentName = kr.kr_name
              temp.push(krProgress);
            }             
          })
        }        
      }     
      return temp;
    }
  }
}
</script>

<style scoped>
.bottom-right {
  width: 50%;
  border-left: 2.5px solid rgb(0 0 0 / 5%);
  border-top: 2.5px solid rgb(0 0 0 / 5%);
  overflow-y: auto;
  height: 50%;
}

.progress-container {
  background: #fafbfc;
  margin-bottom: 8px;
  font-size: 12px;
}

.progress-circle {
  width: 15px;
  height: 15px;
  background: #cef2f4;
  border-radius: 15px;
  margin: 0 3px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  z-index: 5;
}

.progress-circle-inner {
  width: 8px;
  height: 8px;
  background: #83ba5a !important;
  border-radius: 8px;
  display: inline-block;
}
</style>
